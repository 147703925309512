var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      staticClass: "preview-tb-p",
      attrs: {
        closable: "",
        "overflow-auto": "",
        visible: _vm.visible,
        title: _vm.modalTitle + "预览",
        size: "large",
        footer: null,
      },
      on: { cancel: _vm.handleCancel },
    },
    [
      _c(
        "a-tabs",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.isShow,
              expression: "!isShow",
            },
          ],
          on: { change: _vm.callback },
          model: {
            value: _vm.activeKey,
            callback: function ($$v) {
              _vm.activeKey = $$v
            },
            expression: "activeKey",
          },
        },
        _vm._l(_vm.tabData, function (item) {
          return _c("a-tab-pane", {
            key: item.tabId + "," + item.tabName,
            attrs: { tab: item.tabName },
          })
        }),
        1
      ),
      _c("div", [
        _c(
          "div",
          [
            _c("customTable", {
              attrs: {
                "is-report-form": "",
                columns: _vm.columns,
                "data-source": _vm.tableList,
                "row-key": "reportId",
                pagination: false,
                loading: _vm.loading,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }